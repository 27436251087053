import React from 'react';
import chunk from 'lodash/chunk';
import Link from "next/link";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import LinkButton from "@/components/LinkButton";
import styles from "./summaryCard.module.scss";

const SummaryCard = (props) => {
  const {
    title,
    subtitle,
    linkText,
    link,
    children,
    linkButton,
    showHorizontalLine = false,
    analyticsProperties,
  } = props;

  const arrayChildren = React.Children.toArray(children);

  return (
    <div className={styles.summaryCard}>
      <Row className={`${styles.row} mb-4`}>
        <Col md={12} sm={12}>
          <h3 className={styles.header}>{title}</h3>
          <p className={styles.description}>{subtitle}</p>

          {chunk(arrayChildren, 2).map((children, index) => (
            <div className={styles.info} key={index}>
              {children}
            </div>
          ))}

          {link && (
            <Link href={link}>
              <a className={styles.link}>{linkText}</a>
            </Link>
          )}

          {linkButton && (
            <div className='text-center mt-4'>
              <LinkButton href={linkButton} className={styles.button} analyticsProperties={analyticsProperties}>
                {linkText}
              </LinkButton>
            </div>
          )}
          {showHorizontalLine && <div className={styles.horizontalLine} />}
        </Col>
      </Row>
    </div>
  );
};

SummaryCard.label = (props) => (
  <span className={styles.label}>{props.children}</span>
);

SummaryCard.value = (props) => (
  <>
    <span className={styles.divider}>|</span>
    <span className={styles.value}>{props.children}</span>
  </>
);

SummaryCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
  children: PropTypes.node,
  linkButton: PropTypes.string,
  showHorizontalLine: PropTypes.bool,
  analyticsProperties: PropTypes.object,
};

export default SummaryCard;
