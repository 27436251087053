import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { useTranslation } from '@/hooks/translation';
import LoginModal from '@separate/components/LoginModal';
import TextInput from '@separate/components/TextInput';
import styles from './loginWidget.module.scss';

export default function LoginWidget(props) {
  const { cta } = props;
  const { t } = useTranslation();
  const [showLogin, setShowLogin] = React.useState(false);
  const [providedPhoneNumber, setProvidedPhoneNumber] = React.useState("");

  function handleSubmit() {
    setShowLogin(true);
  }

  function isDisabled() {
    return providedPhoneNumber.length < 10;
  }

  return (
    <>
      <TextInput
        type="tel"
        mask="(999) 999-9999"
        unmasked
        name="phone_number"
        placeholder={t('user.phone_number')}
        value={providedPhoneNumber}
        onChange={(value) => setProvidedPhoneNumber(value)}
      />
      <Button onClick={handleSubmit} disabled={isDisabled()} className={styles.button}>
        { cta }
      </Button>
      <LoginModal show={showLogin} onHide={() => setShowLogin(false)} providedPhoneNumber={providedPhoneNumber} />
    </>
  );
}


LoginWidget.propTypes = {
  cta: PropTypes.string,
};

LoginWidget.defaultProps = {
  cta: "Unlock",
};
