import * as React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import LinkButton from '@/components/LinkButton';
import { standard } from '../standardCard.module.scss';
import styles from './ctacard.module.scss';

export default function CTACard({ analyticsProperties, buttonText, className, href, img, text, title, ...extraProps }) {
  return (
    <Card
      className={cx(standard, styles.ctacard, className)}
      {...extraProps}
    >
      <Card.Body
        style={{backgroundImage: `url(${img})`}}
        className={cx(styles.backgroundImage, "d-flex align-items-end p-4")}
      >
        <div className={`${styles.content} d-flex flex-column justify-content-between`}>
          <div>
            <hr />
            <h3>{title}</h3>
            <p>{text}</p>
          </div>
          <div className="mt-2">
            <LinkButton className={styles.button} href={href} analyticsProperties={analyticsProperties}>
              {buttonText}
            </LinkButton>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

CTACard.propTypes = {
  analyticsProperties: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
