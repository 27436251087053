import React from 'react';
import PropTypes from 'prop-types';
import { getArticles } from '@/api/contentStack';
import MemberHome from '@/components/home/Member';
import PublicHome from '@/components/home/Public';
import MetaHead from '@/components/MetaHead';
import { useCurrentUser } from '@/hooks/user';
import { prioritizeArticles } from '@/utilities/articles';

export default function Home({ articles }) {
  const prioritizedArticles = prioritizeArticles(articles);
  const { isLoggedIn } = useCurrentUser();
  const imageUrl = !isLoggedIn && 'https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltdc95d9fb74b7fead/62ac4711a064e356abaf4350/new_public_main.png';

  return (
    <>
      <MetaHead
        imageUrl={imageUrl}
        imageType='image/png'
        imageWidth='1500'
        imageHeight='1016'
      />
      {!isLoggedIn && <PublicHome articles={prioritizedArticles} />}
      {isLoggedIn && <MemberHome articles={prioritizedArticles} />}
    </>
  );
}

Home.propTypes = {
  /**
   * An array of article entries from ContentStack
   */
  articles: PropTypes.array.isRequired,
};

export async function getStaticProps({ locale }) {
  const res = await getArticles(undefined, locale);
  const articles = res[0].slice(0, 5);

  return {
    props: {
      articles,
    },
  };
}
