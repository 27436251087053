import React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { ArticleFeed } from '@/components/Articles';
import CTACard from '@/components/cards/CTACard';
import PodercardCard from '@/components/cards/PodercardCard';
import ChatCard from '@/components/home/Member/ChatCard';
import RewardsWrapper from '@/components/RewardsWrapper';
import { useGetCourses } from '@/hooks/courses';
import { useGetSurveyCenterData } from '@/hooks/surveyCenter';
import { useTranslation } from '@/hooks/translation';
import { useCurrentUser } from '@/hooks/user';
import useWindowSize from '@/hooks/useWindowSize';
import { RewardsTrophyGray } from '@/icons';
import { isFeatureEnabled } from '@/utilities/features';
import SuccessModal from '@separate/components/SuccessModal';
import { PODERSALUD_PRODUCT_ID, PODERCARD_PRODUCT_ID } from '@separate/constants/analytics';
import { useFetchUserReferrals } from '@separate/hooks/user';
import { getCookie, setCookie } from '@separate/utilities/cookies';
import styles from './member.module.scss';
import MembershipDashboard from './MembershipDashboard';

export default function MemberHome({ articles }) {
  const { isLoading: userLoading, user } = useCurrentUser();
  const { isLoading: surveyListIsLoading, data: surveyData } = useGetSurveyCenterData();
  const { isLoading: courseLoading, data: courseData } = useGetCourses();
  const { t } = useTranslation();
  const isOnDesktop = useWindowSize();

  const allowReferrals = user?.is_premium_member && isFeatureEnabled('allow_referrals');

  const courses = courseData?.courses || [];

  const [show, setShow] = React.useState(false);
  const [referral, setReferral] = React.useState({});

  const membershipLevel = () => {
    if (user?.is_premium_member || user?.is_podersalud_user) {
      return 'SEP+';
    }
    return 'SEP';
  };

  const onSuccess = (data) => {
    const findStatusAccepted = data.findLast(referral => referral.status === 'accepted');
    const referralCodeViewed = getCookie('referralCodeAccepted');

    setReferral(findStatusAccepted);
    if(!!findStatusAccepted && (findStatusAccepted?.code !== referralCodeViewed)) setShow(true);
  };

  const { fetchUserReferrals } = useFetchUserReferrals({ onSuccess });

  React.useEffect(() => {
    if(!allowReferrals) return;

    fetchUserReferrals({ userId: user?.id });
  }, [allowReferrals, fetchUserReferrals, user?.id]);

  const referralCodeViewed = () => {
    setCookie('referralCodeAccepted', referral?.code);
    setShow(false);
  };

  return (
    <div className={styles.wrapper}>
      <Row className={cx("mb-3 justify-content-center", styles.userDashboard)}>
        <Col sm={12} className={cx("overflow-hidden", styles.leftCol)}>
          <h1 className={styles.greetings}>{!user?.first_name ? t('home.simple_greeting') : t('home.greeting', { name: user.first_name })}</h1>
          {isOnDesktop && (
            <>
              <div className={styles.membershipText}>
                <p>{t('home.membership_dashboard.membership_level')}</p>
                <span className={cx(membershipLevel() === 'SEP+' && styles.membershipSpan)}>{membershipLevel()}</span>
              </div>
              <ChatCard className={styles.chat} membershipLevel={membershipLevel()} />
            </>
          )}
        </Col>

        <MembershipDashboard
          user={user}
          membershipLevel={membershipLevel()}
          isLoading={userLoading}
          surveyListIsLoading={surveyListIsLoading}
          totalEarnings={+(surveyData?.earnings.total_earned)}
        />
      </Row>

      {(!userLoading && !user?.is_podercard_user) && (
        <Row className="mb-4 justify-content-center">
          <Col sm={12} className="mb-3">
            <PodercardCard
              analyticsProperties={{
                product_id: PODERCARD_PRODUCT_ID,
                logged_in: "true",
              }}
            />
          </Col>
        </Row>
      )}

      <Row className={cx(styles.negativeMarginContainer, "mb-4 justify-content-center")}>
        <Col sm={12}>
          <div className={cx("d-flex justify-content-between align-items-center", styles.headerWrapper)}>
            <h2 className={styles.header}>{t("home.index.articles")}</h2>
            <Link href="/articles">
              <a className="link">{t("home.index.view_more")}</a>
            </Link>
          </div>
          <ArticleFeed articles={articles} asGrid={isOnDesktop} />
        </Col>
      </Row>

      <RewardsWrapper
        courseLoading={courseLoading}
        surveyListIsLoading={surveyListIsLoading}
        surveyData={surveyData?.surveys}
        courses={courses.slice(0,2)}
        isOnDesktop={isOnDesktop}
      />

      {(!userLoading && !user?.is_premium_member) && (
        <Row className={cx(styles.negativeMarginContainer, styles.rowWrapper)}>
          <Col md={12}>
            <h2 className={`${styles.sectionHeader} mb-4`}>{t("home.index.unlock_with_premium")}</h2>
          </Col>
          <Col md={4} className="mb-3 d-flex">
            <CTACard
              href="/premium"
              img="https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt454155322051013a/62509dfade850a7f6b5ac3fa/healthcare_blue.png"
              title={t("home.index.discounted_healthcare_title")}
              text={t("home.index.discounted_healthcare_text")}
              buttonText={t("home.index.discounted_healthcare_button_text")}
              analyticsProperties={{
                product_id: PODERSALUD_PRODUCT_ID,
                logged_in: "true",
                cta_id: "medical_discounts",
              }}
            />
          </Col>
          <Col md={4} className="mb-3 d-flex">
            <CTACard
              href="/premium"
              img="https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltceae44599908f322/62509dfaf686ca7b5e91ab13/content_blue.png"
              title={t("home.index.exclusive_resources_title")}
              text={t("home.index.exclusive_resources_text")}
              buttonText={t("home.index.exclusive_resources_button_text")}
              analyticsProperties={{
                product_id: PODERSALUD_PRODUCT_ID,
                logged_in: "true",
                cta_id: "exclusive_resources",
              }}
            />
          </Col>
          <Col md={4} className="mb-3 d-flex">
            <CTACard
              href="/premium"
              img="https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt55ef84506312b1de/62509dfa6cd4ca7aebeb6b66/chat_blue.png"
              title={t("home.index.support_title")}
              text={t("home.index.support_text")}
              buttonText={t("home.index.support_button_text")}
              analyticsProperties={{
                product_id: PODERSALUD_PRODUCT_ID,
                logged_in: "true",
                cta_id: "real_time_support",
              }}
            />
          </Col>
        </Row>
      )}

      <SuccessModal
        show={show}
        onHide={referralCodeViewed}
        icon={<RewardsTrophyGray />}
        title={t('premium.referral.joined_with_your_referral', { name: referral?.referee?.name })}
        description={t('premium.referral.has_successfully_enrolled', {
          name: referral?.referee?.name,
          discount: referral?.referrer_reward
        })}
        buttonText={t('premium.referral.great')}
      />
    </div>
  );
}

MemberHome.propTypes = {
  articles: PropTypes.array.isRequired
};
