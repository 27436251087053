import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from '@/components/LinkButton';
import { useTranslation } from '@/hooks/translation';
import { courseType, localeType } from '@/types';
import { asCurrency } from '@/utilities/string';
import { surveyTitle } from '@/utilities/survey';
import styles from './quizSpotlightCardDesktop.module.scss';

const QuizSpotlightCardDesktop = ({ course, locale, backgroundImage }) => {
  const { t } = useTranslation();

  if (!course) return null;

  return (
    <div className='d-flex align-items-center justify-content-between'>
      <div className={styles.leftColumn}>
        <img src={backgroundImage} alt="quiz background image" />
      </div>

      <div className={styles.rightColumn}>
        <h4>{surveyTitle(course, locale)}</h4>
        <p className='mt-2'>
          <span className={styles.incentive}>{asCurrency(course.incentive)}</span>
          {course.interview_length && (
            <>
              &nbsp;|&nbsp;
              <span className={styles.length}>{course.interview_length} min</span>
            </>
          )}
        </p>
        <p>{course?.description}</p>
        <LinkButton
          href={`/surveys/${course.id}`}
          className={styles.linkButton}
          activity="rewards_engagement_start"
          position="0"
          type="learn_earn"
        >
          {t('earn.spotlight_start')}
        </LinkButton>
      </div>
    </div>
  );
};

export default QuizSpotlightCardDesktop;

QuizSpotlightCardDesktop.propTypes = {
  course: courseType,
  locale: localeType,
  backgroundImage: PropTypes.string,
};

QuizSpotlightCardDesktop.defaultProps = {
  backgroundImage: 'https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt29257f6b69a5c56a/622a934586e1c60d0c62f3b4/quizBackground.png',
};
