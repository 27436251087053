import React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { ArticleFeed } from '@/components/Articles';
import Button from '@/components/Button';
import CTACard from '@/components/cards/CTACard';
import PodercardCard from '@/components/cards/PodercardCard';
import LoginWidget from '@/components/LoginWidget';
import { useTranslation } from '@/hooks/translation';
import useWindowSize from '@/hooks/useWindowSize';
import LoginModal from '@separate/components/LoginModal';
import { PODERSALUD_PRODUCT_ID, PODERCARD_PRODUCT_ID } from '@separate/constants/analytics';
import styles from './public.module.scss';

export default function PublicHome({ articles }) {
  const { t } = useTranslation();
  const isOnDesktop = useWindowSize();

  const [showLogin, setShowLogin] = React.useState(false);
  const refs = React.useRef([]);

  React.useEffect(() => {
    if (refs) {
      refs.current.forEach(element => {
        observeElement(element);
      });
    }
  }, [refs]);

  const aboutItems = [...t('home.index.public.about.items', { returnObjects: true })];

  function observeElement(element) {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.animate);
          return;
        }
        entry.target.classList.remove(styles.animate);
      });
    });

    observer.observe(element);
  }

  const PoderCardWrapper = () => {
    return (
      <Row className="mb-4 justify-content-center">
        <Col sm={12} className="mb-3">
          <PodercardCard
            analyticsProperties={{
              product_id: PODERCARD_PRODUCT_ID,
              logged_in: "false",
            }}
          />
        </Col>
      </Row>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Row className={styles.main}>
        <Col sm={12} className={styles.backgroundImage} />
        <Col sm={12} className={cx(styles.textWrapper)}>
          <h1>{t("home.index.public.main.title")}</h1>
          <p className="mb-5">{t("home.index.public.main.subtitle")}</p>
          <div className={cx(styles.ctaButtonWrapper, "text-center")}>
            <Button className={cx(styles.ctaButton, "py-2")} onClick={() => setShowLogin(true)}>
              {t("home.index.public.main.cta")}
            </Button>
          </div>
        </Col>
      </Row>
      <LoginModal show={showLogin} onHide={() => setShowLogin(false)} />
      <Row className={`${styles.about} text-center justify-content-center my-4 py-4`}>
        {aboutItems.map((item, index) => (
          <Col key={index} className={styles.items}>
            <img
              src={item.imageURL}
              alt="img"
              className={styles.image}
            />
            <h2
              className={styles.title}
              ref={(element) => {refs.current[index] = element;}}
              style={{ backgroundImage: `url("${item.background}")` }}
            >
              {item.title}
            </h2>
            <p className="mb-0">{item.text}</p>
          </Col>
        ))}
      </Row>
      <Row className={`${styles.join} py-4`}>
        <Col md={12} xs={10}>
          <p className={styles.description} dangerouslySetInnerHTML={{ __html: t("home.index.public.description")}} />
        </Col>
        <Col md={6} sm={12} className="mx-auto">
          <div className={styles.widgetContainer}>
            <LoginWidget cta={t("home.index.public.unlock_now")} />
          </div>
        </Col>
      </Row>

      {isOnDesktop && (
        <PoderCardWrapper />
      )}

      <Row className={cx(styles.articleWrapper, "mb-4 justify-content-center")}>
        <Col sm={12}>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className={styles.header}>{t("home.index.articles")}</h2>
            <Link href="/articles">
              <a className={cx(styles.viewMore, "link")}>{t("home.index.view_more")}</a>
            </Link>
          </div>
          <ArticleFeed articles={articles} asGrid={isOnDesktop} />
        </Col>
      </Row>

      {!isOnDesktop && (
        <PoderCardWrapper />
      )}

      <Row className={styles.negativeMarginContainer}>
        <Col md={12}>
          <h2 className={`${styles.header} mb-4`}>{t("home.index.unlock_with_premium")}</h2>
        </Col>
        <Col md={4} className="mb-3 d-flex">
          <CTACard
            href="/premium"
            img="https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt454155322051013a/62509dfade850a7f6b5ac3fa/healthcare_blue.png"
            title={t("home.index.discounted_healthcare_title")}
            text={t("home.index.discounted_healthcare_text")}
            buttonText={t("home.index.discounted_healthcare_button_text")}
            analyticsProperties={{
              product_id: PODERSALUD_PRODUCT_ID,
              logged_in: "false",
              cta_id: "medical_discounts",
            }}
          />
        </Col>
        <Col md={4} className="mb-3 d-flex">
          <CTACard
            href="/premium"
            img="https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/bltceae44599908f322/62509dfaf686ca7b5e91ab13/content_blue.png"
            title={t("home.index.exclusive_resources_title")}
            text={t("home.index.exclusive_resources_text")}
            buttonText={t("home.index.exclusive_resources_button_text")}
            analyticsProperties={{
              product_id: {PODERSALUD_PRODUCT_ID},
              logged_in: "false",
              cta_id: "exclusive_resources",
            }}
          />
        </Col>
        <Col md={4} className="mb-3 d-flex">
          <CTACard
            href="/premium"
            img="https://images.contentstack.io/v3/assets/blt224b6cd2a9d5dbf3/blt55ef84506312b1de/62509dfa6cd4ca7aebeb6b66/chat_blue.png"
            title={t("home.index.support_title")}
            text={t("home.index.support_text")}
            buttonText={t("home.index.support_button_text")}
            analyticsProperties={{
              product_id: {PODERSALUD_PRODUCT_ID},
              logged_in: "false",
              cta_id: "real_time_support",
            }}
          />
        </Col>
      </Row>
    </div>
  );

}

PublicHome.propTypes = {
  /**
   * An array of article entries from ContentStack
   */
  articles: PropTypes.array.isRequired,
};
